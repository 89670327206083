
@tailwind base;
@tailwind components;
@tailwind utilities;

body,.root,
#root,
#docs-root {
  --primary: #304659;
  --secondary: #4bc2c5;
  --accent: #5bd1d7;
  --white: #ffffff;
  --black: #1F1E1C;
  --grey: #304659;
  --light-blue: #E1E9F0;
  --light-grey: #F2F2F2;
  --medium-grey: #EEEEEE;
  --medium-grey-darker: #DEDEDE;
  --dark-grey: #666666;
  --background: #fff;
  --main: #0d0106;
  --header: #2d3748;
  --alert: #dd0a35;
  --radius-std: 10px;
  --radius-btn: 25px;
  --padding-std: 20px;
  --shadow: 0px 3px 8px rgba(0,0,0,0.2);
  --font-weight-bold: 700;
}

/* =============================================================================
Imports
========================================================================== */

  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

/* =============================================================================
  Layout
========================================================================== */
  #content-wrapper {
    padding: 20px;
  }

  #content {
    opacity: 0;
    transition: opacity 250ms ease;
    margin-bottom: 100px;
    &.loaded {
      opacity: 1;
    }
  }

/* =============================================================================
  Helpers
========================================================================== */
  .shadow {
    box-shadow: var(--shadow);
  }

  .__embed {
    max-width: 900px !important;
    > span {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

/* =============================================================================
  Print
========================================================================== */
  .print-wrapper {
    padding: 30px;
    .tab-content {
      &.first {
        border: none;
        padding-top: 0;
      }
      padding-bottom: 0;
      padding-top: 2rem;
    }
    .field-wrapper {
      .value.textarea {
        min-height: 44px;
      }
    }
  }
/* =============================================================================
  Colors
========================================================================== */

html,body {
  font-family: 'Lato', sans-serif;
  color: var(--primary);
  background-color: var(--white);
}

.bg-light-grey {
  background-color: var(--light-grey);
}

.secondary {
  color: var(--secondary);
}
.medium-grey {
  color: var(--medium-grey);
}
.dark-grey {
  color: var(--dark-grey);
}
.accent {
  color: var(--accent);
}

/* =============================================================================
  Typography
========================================================================== */


  h1,h2,h3,h4,h5,h6 {
    font-weight: var(--font-weight-bold);
    line-height: 1.2;
  }
  h1 {
    font-size: clamp(24px, 5vw, 35px);
  }
  h2 {
    font-size: clamp(20px, 4vw, 28px);
  }
  h3 {
    font-size: clamp(19px, 3.2vw, 25px);
  }
  h4 {
    font-size: clamp(18px, 3vw, 20px);
  }

  .page-sub-header {
    text-transform: uppercase;
    color: var(--dark-grey);
    font-size: clamp(14px, 2vw, 20px);
  }

/* =============================================================================
  Buttons
========================================================================== */
  .button,button {
    background-color: var(--primary);
    color: var(--white);
    padding: 0.75em 1.2em;
    border-radius: var(--radius-btn);
    font-weight: var(--font-weight-bold);
    line-height:1.1;
    align-items: center;
    &.disabled,&[disabled] {
      opacity: 0.4;
    }
    &:not(.hidden) {
      display: inline-flex;
    }
  }
  .button-small {
    font-size: 0.75em;
  }
  .button.br0 {
    border-radius: 0 !important;
  }

  .button svg{
    display: inline-block;
  }
/* =============================================================================
  Forms
========================================================================== */
  input,select {
    background-color: var(--light-grey);
  }

/* =============================================================================
  Tables
========================================================================== */

  table {
    border: none;
    th,td {
      text-align: left;
      padding: 18px 20px;
    }
    tr {
      &.unread {
        td {
          &.last-name {
            font-weight: bold;
          }
        }
      }
      &:nth-child(even) {
        background-color: #EBF2F7;
      }
    }
  }
  table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
    }

  th.sorter {
    cursor: pointer;
  }

/* =============================================================================
  Tabs
========================================================================== */
  .tabs-wrapper {
  }
  .tab {
    cursor: pointer;
    flex-shrink: 1;
    padding:10px 16px;
    background-color: var(--medium-grey);
    border-right-width: 1px;
    border-right-color: var(--white);
    &.active {
      background-color: var(--primary);
      color: var(--white);
    }
    &:hover {
      &:not(.active) {
        background-color: var(--medium-grey-darker);
      }
    }
  }
  .tab-content {
    padding-top: 1.25rem;
    padding-bottom: 70px;
    border-top-width: 2px;
    border-top-color: var(--primary);
  }

  .tab-selector-wrapper {
    margin-top: 2.5rem;
    margin-bottom: 0;
    select {
      background-color: var(--medium-grey);
      width: 100%;
      padding: 10px 5px;
      font-size: clamp(24px, 5vw, 35px);
      border-radius: 0 !important;
    }
  }

/* =============================================================================
  Fields
========================================================================== */
  .field-group-header {
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    margin-bottom: 1.25rem;
    margin-top: 2rem;
    background-color: var(--medium-grey);
    text-transform: uppercase;
  }
  .field-group-sub-header {
    font-size: 1rem;
    text-transform: uppercase;
    border-bottom: 1px solid var(--primary);
    padding-bottom: 0.25rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
  .field-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    .label {
    }
    .value {
      flex-grow: 1;
      padding: 10px;
      background-color: var(--light-blue);
      min-height: 44px;
      overflow-wrap: break-word;
      hyphens: manual;
      &.textarea {
        min-height: 90px;
      }
    }
  }

  .grid {
    > div {
      .field-group-sub-header {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

/* =============================================================================
  Video
========================================================================== */
  .embed-youtube {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
  }
  .video-wrapper {
    width: 100%;
  }
/* =============================================================================
  Offline Banner
========================================================================== */
  #offline-banner {
    width: 100%;
    margin: -20px;
    padding: 10px 20px;
    background-color: var(--alert);
    color: var(--white);
    margin-bottom: 20px;
    width: calc(100% + 40px);
  }
/* =============================================================================
  Item
========================================================================== */
  .item {
    position: relative;
    border-radius: var(--radius-std);
    background-color: var(--light-grey);
    padding: var(--padding-std);
    overflow: hidden;
    margin-top: 1.25rem;
    &.tip {
      padding-right: calc(var(--padding-std) * 3);
      background-color: var(--secondary);
      color: var(--white);
      .close {
        svg {
          fill: var(--white);
        }
      }
    }
    .close {
      position: absolute;
      top: calc(var(--padding-std) * 0.25);
      right: calc(var(--padding-std) * 0.25);
      svg {
        fill: var(--primary);
      }
    }
    &.news {
      padding: 0;

    }
    .item-padding {
      padding: var(--padding-std);
    }
  }

  .item-title {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .item-date {
    font-size: 12px;
  }

/* =============================================================================
  News
========================================================================== */
  .news-article-wrapper {
    max-width: 860px;
    margin: 1.25rem auto 70px;
    padding: var(--padding-std);
    border-radius: var(--radius-std);
    background-color: var(--light-grey);
    p,img,ul,ol,.button {
      margin-bottom: 1.25rem;
    }
    h1,h2,h3 {
      margin-bottom: 0.25em;
      margin-top: 1em;
    }
    ul {
      list-style-type: disc;
      margin-left: 1.25rem;
    }
  }

  .featured-image {
    margin-bottom: 1.25rem;
  }
/* =============================================================================
  Menu
========================================================================== */
  .menu-item {
    opacity: 0.6;
    transition: opacity 250ms ease;
    margin-bottom: 24px;
    a {
      padding: 1rem 1.5rem;
      min-width: 30px;
      text-align: center;
      display: block;
      color: var(--white);
      fill: var(--white);
      font-size: 0.75rem;
      text-transform: uppercase;
      p {
        display: none;
      }
    }
    svg {
      height: 23px;
      width: auto;
      display: block;
      margin: 0 auto;
    }
    &.active {
      opacity:1;
      a {
      }
    }
    &.home {
      opacity: 1;
      a {
        width: 70px;
        height: 70px;
        border-radius: 35px;
        border: 4px solid var(--white);
        position: relative;
        margin-top: -30px;
        background-color: var(--grey);
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          height: 35px;
        }
      }
    }
  }

/* =============================================================================
  Filter
========================================================================== */

  #filter {
    position: relative;
    &:before {
      content:"";
      display: block;
      position: absolute;
      right: 45px;
      bottom: 100%;
      width: 24px;
      height: 21px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='21' viewBox='0 0 24 21'%3E%3Cpath id='Polygon_1' data-name='Polygon 1' d='M12,0,24,21H0Z' fill='%23eee'/%3E%3C/svg%3E%0A");
    }
    input,select {
      background-color: var(--white);
    }
  }

/* =============================================================================
  Pagination
========================================================================== */

  .pagination{
      display: flex;
      > li {
        padding: 2px 6px;
        a {
          display: block;
          position: relative;
          line-height: 1;
          padding: 8px;
          min-width: 32px;
          text-align:center;
          text-transform: uppercase;
          background-color: var(--primary);
          color: var(--white);
        }
        &:not(.pagination-item) {
          a {
            background-color: var(--white);
            color: var(--primary);
          }

        }
        &.first,&.last {
          display: none;
        }
        &.active {
          a {
            background-color: var(--primary);
            color: var(--white);
          }
        }
      }
  }
.news_text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}
.disabled{
    display: none;
}
/*.active{
    background-color:#304659;
    color: white;
}*/

  .modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }

  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  /* =============================================================================
    Print
  ========================================================================== */
    .print {
      background-color: transparent !important;
      border-radius: none !important;
      position: relative;
      &:after {
        position: relative;
        content: 'Source: The Countess of Munster Musical Trust Trustees App';
        font-size: 14px;
        margin-top: 20px;
        color: var(--grey);
      }
    }

  /* =============================================================================
    Footer
  ========================================================================== */
    #footer {
      background-color: var(--grey);
      position: fixed;
      bottom: 0;
      left: 0;
    }

  /* =============================================================================
    Breakpoint: Medium / Tablets
  ========================================================================== */
    @media print, screen and (min-width: 48em) {
      #content-wrapper {
        padding: 30px;
      }
      /* =============================================================================
        Offline Banner
      ========================================================================== */
        #offline-banner {
          margin: -30px;
          width: calc(100% + 60px);
          margin-bottom: 20px;
        }
      /* =============================================================================
        Menu
      ========================================================================== */
        .menu-item {
          font-size: 0.875em;
          a {
            padding: 1rem 2.25rem;
            p {
              min-width: 60px;
              display: inherit;
            }
          }
          &.home {
            a {
              width: 80px;
              height: 80px;
              border-radius: 40px;
              margin-top: -25px;
            }
          }
        }

    }

  /* =============================================================================
    Breakpoint: Large
  ========================================================================== */
    @media print, screen and (min-width: 85.3em) {


    }
  /* =============================================================================
    Breakpoint: XLarge
  ========================================================================== */
    @media print, screen and (min-width: 120em) {


    }
